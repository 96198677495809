import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Switch } from 'antd'
import Button from '@components/Button/Button'
import Wrapper from '@components/Wrapper/Wrapper'
import loadMarketoForm from '@helpers/load-marketo-form'

const Userinfo = () => {
  const emailRegex = new RegExp(
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  )
  const [checkedState, setCheckedState] = useState(false)
  const [checked1State, setChecked1State] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [title, setTitle] = useState()
  const [companyName, setCompanyName] = useState()
  const [email, setEmail] = useState()
  const [contactNum, setContactNum] = useState()
  const handleSwitchChange = (e) => {
    setCheckedState(e)
  }
  const handleSwitch1Change = (e) => {
    setChecked1State(e)
  }

  useEffect(() => {
    if (
      checkedState &&
      firstName &&
      lastName &&
      title &&
      companyName &&
      email &&
      emailRegex.test(email) &&
      contactNum
    ) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [
    checkedState,
    checked1State,
    firstName,
    lastName,
    title,
    companyName,
    email,
    contactNum,
  ])

  useEffect(() => {
    sessionStorage.setItem('FirstName', '')
    sessionStorage.setItem('LastName', '')
    sessionStorage.setItem('Title', '')
    sessionStorage.setItem('Company', '')
    sessionStorage.setItem('Email', '')
    sessionStorage.setItem('Phone', '')
    sessionStorage.setItem('iDDSurveyCapture', 'NR')
  }, [])

  const marketoId = 2853
  let [marketoForm, setMarketoForm] = useState({})

  const handleFirstNameChange = (v) => {
    setFirstName(v.target.value)
    sessionStorage.setItem('FirstName', v.target.value)
  }
  const handleLastName = (v) => {
    setLastName(v.target.value)
    sessionStorage.setItem('LastName', v.target.value)
  }
  const handleTitle = (v) => {
    setTitle(v.target.value)
    sessionStorage.setItem('Title', v.target.value)
  }
  const handleCompanyName = (v) => {
    setCompanyName(v.target.value)
    sessionStorage.setItem('Company', v.target.value)
  }
  const handleEmail = (v) => {
    setEmail(v.target.value)
    sessionStorage.setItem('Email', v.target.value)
  }
  const handleContactNum = (v) => {
    setContactNum(v.target.value)
    sessionStorage.setItem('Phone', v.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (checkedState) {
      const formValues = {
        FirstName: sessionStorage.getItem('FirstName'),
        LastName: sessionStorage.getItem('LastName'),
        Title: sessionStorage.getItem('Title'),
        Company: sessionStorage.getItem('Company'),
        Email: sessionStorage.getItem('Email'),
        Phone: sessionStorage.getItem('Phone'),
        iDDSurveyCapture: sessionStorage.getItem('iDDSurveyCapture'),
        optinDataCollection: checkedState,
        optinMarketingCommunications: checked1State,
      }

      marketoForm?.setValues(formValues)

      const validated = marketoForm?.validate()
      if (validated) {
        MktoForms2.whenReady(function (form) {
          form.submit()
          if (checkedState) {
            navigate('/survey-slider')
          }
        })
      } else {
        console.log('Form not validated')
        marketoForm.showErrorMessage()
      }
    }
  }

  useEffect(() => {
    loadMarketoForm(marketoId, (marketoFormObj) => {
      if (marketoFormObj) {
        setMarketoForm(marketoFormObj)
        marketoFormObj.onSuccess((values, followUpUrl) => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'page.submit',
            'page.submit': {
              v: 12,
              title: document.title,
              product: 'generic',
              done: 'true',
              id: marketoId,
            },
          })

          return false
        })
      }
    })
  }, [])

  return (
    <Wrapper>
      <div className="user-info">
        <div className="user-info__header headingLegendBold">
          Please provide us with your business contact details so we can send
          you the detailed analysis and recommendations based on your inputs.
        </div>
        <div className="user-info__form-wrapper">
          {/*<div id="mockup-form">
            <MarketoFormModule blok={formProps} />
          </div>*/}
          <form className="user-info__form">
            <div className="user-info__form-row">
              <label className="user-info__form-label textElement">
                First Name
                <input
                  onChange={handleFirstNameChange}
                  id="FirstName"
                  placeholder="Enter First Name"
                  className="user-info__form-input"
                  value={firstName}
                />
              </label>
              <label className="user-info__form-label textElement">
                Last Name
                <input
                  onChange={handleLastName}
                  id="LastName"
                  placeholder="Enter Last Name"
                  className="user-info__form-input"
                  value={lastName}
                />
              </label>
            </div>
            <div className="user-info__form-row">
              <label className="user-info__form-label textElement">
                Position Title
                <input
                  onChange={handleTitle}
                  id="Title"
                  placeholder="Enter Position Title"
                  className="user-info__form-input"
                  value={title}
                />
              </label>
              <label className="user-info__form-label textElement">
                Company Name
                <input
                  onChange={handleCompanyName}
                  id="Company"
                  placeholder="Enter Company Name"
                  className="user-info__form-input"
                  value={companyName}
                />
              </label>
            </div>
            <div className="user-info__form-row">
              <label className="user-info__form-label textElement">
                Business Email Address
                <input
                  onChange={handleEmail}
                  id="Email"
                  placeholder="Enter Business Email Address"
                  className="user-info__form-input"
                  value={email}
                />
              </label>
              <label className="user-info__form-label textElement">
                Contact Number
                <input
                  onChange={handleContactNum}
                  id="Phone"
                  placeholder="Enter Contact Number"
                  className="user-info__form-input"
                  value={contactNum}
                />
              </label>
            </div>
            <div className="user-info__form-row-last">
              <div className="user-info__text">
                I agree to the{' '}
                <a
                  href="https://t.sidekickopen04.com/s3t/c/5/f18dQhb0S7kv8cVQjxVFQrRJ59hl3kW7_k2842QxXtmW1CX5Qh7wzhR8W2R17kF2bzNMMf6f3dxZ01?te=W3R5hFj4cm2zwW41PG051Q25F1W3M1YQr41TRgPW45TRgW3K2B2XW43Tw8Z4hMntNW43SfLS43T4N9W4hLywB3R5hFjW4cbjZB1mp7wVW1SbFVJ4m8kj1W4fLLWk4cLrMBW43T3Vx2zVF0yW45LM6y1mp7ylW3K8Qzm1GFZjDW4hLZmB1SbDjbW1mp7y53K76ZWW3zd14s3K8QzmW1mpz0B3N-K8wW2vz2131N5ZfDW1S1nKx1J9gWpW22TGRm24SbxNW1-Zdmt1S3sXcW24QZnc1YZ0V1W24TsXZ1J7D9lW1V19hB1X2dfsW3H3bCk2dLp3LW2120KS1mrcG3W49M9lg3F7xMKW1mpYyZ4hMnLPW3K9cSd3Fbt5GW2120QD1Q3kzpW3BQmkv1N6mXCW3F5Qkt3K1F6nW3GMxh81N4h_8W1VpZVt2sD9c-W3M2gCX3JKdXZW1Q4vYh1VnkGVW1ZlfJj1YZYcjW3H4qwL3GC6MQW1M_KB91M_KB9W1-YRdV22YpLZW1X0yzt1N6Pt8W1Y-GD_2sT8NpW41Wvys1mrcFHW3bbSV-2CPrBRW1VpB4N4rk2JQW3W0hhL2sCrVKW3VG8J72vHnkKW2sNx_m3W0hhWW1SvsLm4thcjMW2sNwHs3SLSgNW3Xw1hw3bBdxdW2sNyCP2sN47QW41q7qZ2vsFFVW1T_XJ61pTPxkW4fDSRC3_zNf_W2-F4cL38vpPjW38wKbT34qG9ZW2HJnF53SCslxW24__wr45LMyGW4hwXmM3dsHm1W2vt8jS4cJ2Vxf3K2WHM04&si=5692819163054080&pi=66ea96bc-54c6-4293-8dc3-5d24678dd538"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{' '}
                and the use of my personal information as described in the
                Autodesk &nbsp;
                <a
                  href="https://t.sidekickopen04.com/s3t/c/5/f18dQhb0S7kv8cVQjxVFQrRJ59hl3kW7_k2842QxXtmW1CX5Qh7wzhR8W2R17kF2bzNMMf6f3dxZ01?te=W3R5hFj4cm2zwW41PG051Q25F1W3M1YQr41TRgPW45TRgW3K2B2XW43Tw8Z4hMntNW43SfLS43T4N9W4hLywB3R5hFjW4cbjZB1mp7wVW1SbD6L41Y89RW4hDk5V43Tw8JW4hMnLP3K9cSdW3Fbt5G1SbFgqW3T4Fqr4r1BW2W1pMy803yTt0WW1mrcF01P-g4mW3T2_d71JxwjJW41kBx43zhVBDW3H4Tqh1JxwY5W1mrcF11V0lgqW3K23nH1W-tF4W3C6X6D1Vq37PW23gjJr1S1nb5W3BMYqN2121fpW3C88KZ25fgvPW24SDxW1V1fqsW3JJpKt1S3sMxW1Sr5s71QssptW2szFP32szFP3W2sD8Tc20ZshDW1Q4w0-1Q2SchW1-ZFXB2123_nW3X-qTw41kBPGW34qcNg3BXd-GW3BMYln3KcGQkW2HT9Sr2Rs299W2PNMk92RstP9W2PtxBB2HT9SrW383-yB2RT-65W2PtxBj34yKyMW2HTvV63z3pCSW2PtxBK38b_xbW2RNYbN1V8dcJW2sBX8-1N0dkTW3zhrq_1VwQZmW3j61_L251bRhW3h_Kc02qDq2YW2zDSCH4rnq9pW32yvsJ1QlkrqW2-F6Jw1XfV6cW43j6YW1pTn7NF3K8RlNn49g1&si=5692819163054080&pi=66ea96bc-54c6-4293-8dc3-5d24678dd538"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Statement
                </a>
              </div>
              <div className="user-info__switch-wrapper">
                <Switch
                  onChange={(e) => handleSwitchChange(e)}
                  checked={checkedState}
                />
              </div>
            </div>
            <div className="user-info__form-row-last">
              <div className="user-info__text">
                I agree to receive electronic messages (including marketing
                e-mails at the address you provided) from Autodesk Construction
                Solutions, including educational content, product updates,
                webinars, events and general announcements. Autodesk
                Construction Solutions will personalize the content we send you
                based on how you interact with our messages. You are in control.
                <a
                  href="https://t.sidekickopen04.com/s3t/c/5/f18dQhb0S7kv8cVQjxVFQrRJ59hl3kW7_k2842QxXtmW1CX5Qh7wzhR8W2R17kF2bzNMMf6f3dxZ01?te=W3R5hFj26QkG_W43TDd849S7GgW3T1MdG3ZZpmqW1JwJwq43PrftW3XtNr93_rh4DW3zbV8X45TQpdW3K8PTl3F6jVZW3z8nYl3R5h120&si=5692819163054080&pi=66ea96bc-54c6-4293-8dc3-5d24678dd538"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;Manage
                </a>{' '}
                your preference at anytime. View our{' '}
                <a
                  href="https://t.sidekickopen04.com/s3t/c/5/f18dQhb0S7kv8cVQjxVFQrRJ59hl3kW7_k2842QxXtmW1CX5Qh7wzhR8W2R17kF2bzNMMf6f3dxZ01?te=W3R5hFj4cm2zwW3FbtcS4fN0QNW4fJg0T1JwJwqW43Prft3XtNr9W3_rj2T3T4Fqr22f3&si=5692819163054080&pi=66ea96bc-54c6-4293-8dc3-5d24678dd538"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Statement
                </a>
              </div>
              <div className="user-info__switch-wrapper">
                <Switch
                  onChange={(e) => handleSwitch1Change(e)}
                  checked={checked1State}
                />
              </div>
            </div>
            <div className="user-info__button-wrapper">
              <Button
                onClick={onSubmit}
                type="submit"
                disabled={submitDisabled}
                label="Submit and Start"
              />
              {/* </Link> */}
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  )
}

export default Userinfo
